import React, {useState} from 'react';

import LogoMark from  '../img/logo/logo_mark.png';
import LogoMarkWhite from  '../img/logo/logo_mark_white.png';
import LogoDarkMark from  '../img/logo/logo_dark.png';
import AppScreen from '../img/landing/app.png';
import AptosLogo from '../img/landing/aptos.png';
import SuiLogo from '../img/landing/sui.png';
import SendIco from  '../img/landing/send.png';
import MultiChainDexImg from '../img/landing/mutichaindex.png';
import LaunchpadImg from '../img/landing/launchpad.png';
import YieldFarmingImg from '../img/landing/yieldfarming.png';
import StakingImg from '../img/landing/staking.png';
import LendingImg from '../img/landing/lending.png';
import GovernanceImg from '../img/landing/governance.png'

import Emo_1_Icon from '../img/landing/emo_6.png';
import Emo_2_Icon from '../img/landing/emo_5.png';
import Emo_3_Icon from '../img/landing/emo_2.png';
import Emo_4_Icon from '../img/landing/emo_1.png';
import Emo_5_Icon from '../img/landing/emo_2.png';
import Emo_6_Icon from '../img/landing/emo_3.png';

import GithubIcon from '../img/social/github.png';
import MediumIcon from '../img/social/medium.png';
import TwitterIcon from '../img/social/twitter.png';
import DiscordIcon from '../img/social/discord.png';
import TelegramIcon from '../img/social/telegram.png';

import Starlg from '../img/landing/star_lg.png';
import Starsm from '../img/landing/star_sm.png';

import BurgerIcon from '../img/logo/burger.png';
import CloseIcon from '../img/logo/close.png';

const Landing = () => {

  const [status, setStatus] = useState(false);

 
    return (<div >
      <nav class="flex justify-between px-8 md:px-12 lg:px-16 xl:px-20  py-4 items-center bg-[#F5F5F5]">
          <img src={LogoMark} className="hidden sm:block" />
          <img src={LogoDarkMark} className="block sm:hidden"/>
          <div class="flex items-center">
          
            <ul class="hidden sm:flex items-center space-x-6">
              <li class="font-semibold text-gray-700">
                <a href=''>Home</a>
              </li>
              <li class="font-semibold text-gray-700">
                <a href='contact@trellis.fi'>Contact</a>
              </li>
              <li class="font-semibold text-gray-700">
                <a href='https://trellisfi.medium.com/' target='_blank'>Blog</a>
              </li>
              
          
            
              <li className='pl-4 lg:pl-12 '>
                <a href="https://app.trellis.fi" class="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 bg-[#000000] rounded-lg hover:bg-gray-800 focus:shadow-outline focus:outline-none">
                Launch App
                </a>
              </li>
            </ul>

            {/* <img src={BurgerIcon} className="cursor-pointer btn-border-1 rounded-full"/>
            
            <img src={CloseIcon} className="cursor-pointer btn-border-2 rounded-full"/> */}
            {!status ? (
            <div className="cursor-pointer block sm:hidden p-2 rounded-full border border-black" onClick={() => { setStatus(!status)}}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
            </div> ) :
            (
            <div className="cursor-pointer block sm:hidden p-2 rounded-full border border-black" onClick={() => { setStatus(!status)}} >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>

            </div>
            )
            }

          </div>
        </nav>
        {
        status && (
        <ul class="block sm:hidden items-center text-center mt-4">
              <li class="font-semibold text-gray-700">
                <a href=''>Home</a>
              </li>
              <li class="font-semibold text-gray-700">
                <a href='contact@trellis.fi'>Contact</a>
              </li>
              <li class="font-semibold text-gray-700">
                <a href='https://trellisfi.medium.com/'>Blog</a>
              </li>
              
          
            
              <li className='pl-4 lg:pl-12 mt-4'>
                <a href="https://app.trellis.fi" class="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 bg-[#000000] rounded-lg hover:bg-gray-800 focus:shadow-outline focus:outline-none">
                Launch App
                </a>
              </li>
            </ul>
        )
      }

      <div className='px-8 md:px-12 lg:px-16 xl:px-20'>
        <section>
          <div className='block md:flex py-16 items-center'>
            <div className='w-full md:w-1/2'>
             <div className='w-full md:w-3/4'>
              <div  className='flex items-center  '>
                <h1 className='text-5xl font-bold mr-10'>Trellis
                
                </h1>
                <img src={AptosLogo} style={{height: '35px'}} className='cursor-pointer'/>
              </div>
              <p className='' >
                <span className='text-3xl font-semibold'>aims to provide innovative DeFi solutions in the Defi market.</span>
                <div  className='flex items-center'>
                  <span className=' mr-20'>Empowering the Future of DeFi</span>
                  <img src={SuiLogo} style={{height: '35px'}}  className='cursor-pointer'/>
                </div>
              </p>
              <div className='flex mt-8'>
                <div className='mr-10 flex items-center'>
                  <a href='https://docs.trellis.fi' className='underline text-xl mr-2'>Trellis Docs
                  </a>
                  <img src={SendIco} style={{height: '20px'}}></img>
                   
                </div>
                <div className='flex items-center'> 
                  <a href='https://app.trellis.fi' className='underline text-xl mr-2'>Launch App</a>
                  <img src={SendIco} style={{height: '20px'}}></img>
                </div>
              </div>
             </div>
              
            </div>
            <div className='w-full md:w-1/2 mt-10 md:mt-0 md:mb-0'>
              <img src={AppScreen}  className='float-right w-full mb-10 md:mb-0'/>
            </div>
          </div>


        </section>

        <section>
          <p className='text-3xl md:text-4xl lg:text-5xl font-bold text-center mt-10 mb-10'>Innovative DeFi Solutions</p>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-12 lg:gap-20">
            <div className="solution-card p-4">
              <div className='solution-card text-center p-8'>
                <div className='text-center'>
                <img src={MultiChainDexImg} className='m-auto' />
                </div>
                <p className='text-xl mb-4 mt-3 font-bold'>Multi-Chain DEX</p>
                <p>Trellis offers a multi-chain decentralized exchange platform that enables users to trade assets with low fees and high liquidity. This offers an innovative solution for the DeFi space, providing users with a secure and efficient way to manage their digital assets.</p>
              </div>
            </div>
            
            <div className="solution-card p-4">
              <div className='solution-card text-center p-8'>
                <div className='text-center'>
                <img src={LaunchpadImg} className='m-auto' />
                </div>
                <p className='text-xl mb-4 mt-3 font-bold'>Launchpad</p>
                <p>Trellis offers a launchpad for new DeFi projects and tokens, allowing users to participate in early-stage projects and earn rewards.</p>
              </div>
            </div>

            <div className="solution-card p-4">
              <div className='solution-card text-center p-8'>
                <div className='text-center'>
                <img src={YieldFarmingImg} className='m-auto' />
                </div>
                <p className='text-xl mb-4 mt-3 font-bold'>Yield Farming</p>
                <p>Trellis offers a yield farming platform where users can earn rewards by providing liquidity to various DeFi protocols. Benefit from high-yield returns while contributing to the growth of the DeFi ecosystem.</p>
              </div>
            </div>

            <div className="solution-card p-4">
              <div className='solution-card text-center p-8'>
                <div className='text-center'>
                <img src={LendingImg} className='m-auto' />
                </div>
                <p className='text-xl mb-4 mt-3 font-bold'>Lending Protocol</p>
                <p>Trellis offers a multi-chain decentralized lending platform that provides users with the ability to earn interest on their assets while also allowing borrowers to access capital without the need for a traditional financial intermediary.</p>
              </div>
            </div>

            <div className="solution-card p-4">
              <div className='solution-card text-center p-8'>
                <div className='text-center'>
                <img src={StakingImg} className='m-auto' />
                </div>
                <p className='text-xl mb-4 mt-3 font-bold'>Staking  </p>
                <p>Holders of Trellis tokens can earn rewards by participating in platform growth through staking, providing incentives for long-term engagement with the platform.</p>
              </div>
            </div>

            <div className="solution-card p-4">
              <div className='solution-card text-center p-8'>
                <div className='text-center'>
                <img src={GovernanceImg} className='m-auto' />
                </div>
                <p className='text-xl mb-4 mt-3 font-bold' >Governance</p>
                <p>Trellis offers community to collectively make decisions and take actions regarding the development and direction of the Trellis project. This include voting on proposals, suggesting improvements, and participating in discussions to ensure the project is aligned with the community's values and goals.</p>
              </div>
            </div>
          </div>
          
         
        </section>

        <section>
            <p className='text-3xl md:text-4xl lg:text-5xl font-bold text-center mt-20 mb-10'>Why Trellis?</p>
            <div class="grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-20">
              <div className='p-4 min-h-20 text-center'>
                <div style={{minHeight: '120px'}} className="flex items-center">
                 <img src={Emo_1_Icon} className="m-auto" />
                </div>
               
                <p className='text-xl mb-4 mt-3 font-bold' >Decentralized</p>
                <p>Trellis offer a decentralized platform with a focus on transparency and user control.</p>
              </div>
              <div className='p-4 min-h-20 text-center'>
                <div style={{minHeight: '120px'}} className="flex items-center">
                  <img src={Emo_2_Icon} className="m-auto" />
                 </div>
                  <p className='text-xl mb-4 mt-3 font-bold' >User-friendly</p>
                  <p>Trellis offer a simple and intuitive interface for users to participate in various DeFi activities.</p>
              </div>
              <div className='p-4 min-h-20 text-center'>
                <div style={{minHeight: '120px'}} className="flex items-center">
                  <img src={Emo_3_Icon} className="m-auto" />
                 </div>
                <p className='text-xl mb-4 mt-3 font-bold' >Multi-Chain</p>
                <p>Trellis allow users to interact with various blockchain networks and access a wide range of DeFi services and financial products.</p>
              </div>
              <div className='p-4 min-h-20 text-center'>
                <div style={{minHeight: '120px'}} className="flex items-center">
                    <img src={Emo_4_Icon} className="m-auto" />
                  </div>
                  <p className='text-xl mb-4 mt-3 font-bold' >Innovative</p>
                  <p>Innovative DeFi solutions that offer a wide range of financial services on a decentralized platform</p>
              </div>
              <div className='p-4 min-h-20 text-center'>
                <div style={{minHeight: '120px'}} className="flex items-center">
                    <img src={Emo_5_Icon} className="m-auto" />
                  </div>
                  <p className='text-xl mb-4 mt-3 font-bold' >Secured</p>
                  <p>Trellis offer high degree of security, privacy, and transparency due to its decentralized nature.</p>
              </div>
              <div className='p-4 min-h-20 text-center'>
                <div style={{minHeight: '120px'}} className="flex items-center">
                  <img src={Emo_6_Icon} className="m-auto" />
                 </div>
                  <p className='text-xl mb-4 mt-3 font-bold' >Open-Source</p>
                  <p>The open-source nature of Trellis also encourages transparency, accountability, and innovation within the platform.</p>
              </div>
            </div>
        </section>
        <section>
          <div className="mt-20 text-center mb-32 relative">
            <img src={Starlg} className="absolute" style={{top:'-45px', left:'202px'}} />
            <img src={Starsm} className="absolute" style={{top:'-40px', left:'264px'}} />
            <img src={Starlg} className="absolute" style={{top:'-45px', right:'252px'}} />
            <img src={Starsm} className="absolute" style={{top:'-65px', right:'202px'}} />
            <p className='text-3xl md:text-4xl lg:text-5xl font-bold text-center mt-10 mb-10'>
              Ready to start your DeFi journey?
            </p>
            <a href="https://app.trellis.fi" class="inline-flex items-center justify-center h-12 w-1/2 m-auto font-medium tracking-wide text-white transition duration-200 bg-[#000000] rounded-lg hover:bg-gray-800 focus:shadow-outline focus:outline-none">
                Launch App
            </a>
          </div>
        </section>

       </div>
       
      <section>
        <nav class="block text-center md:text-left md:flex justify-between  px-8 md:px-12 lg:px-16 xl:px-20  py-4 items-center bg-[#000000]">
          <img src={LogoMarkWhite} className=" mb-8  md:mb-0" />
          <div class="block md:flex items-center">
          
            <ul class="block md:flex items-center md:space-x-6">
              <li class="font-semibold text-white">
                <a href='contact@trellis.fi'>Contact Us</a>
              </li>
              <li class="font-semibold text-white">
                <a href='https://docs.trellis.fi'>Gitbook</a>
              </li>
              <li class="font-semibold text-white">
                <a href=''>Terms of use</a>
              </li>
              
          
            
              <li className='pl-4 lg:pl-12 pt-4 md:pt-0'>
                <a href="https://app.trellis.fi" class="border border-white inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 bg-[#000000] rounded-lg hover:bg-gray-800 focus:shadow-outline focus:outline-none">
                Launch App
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <div className="bg-[#000000] flex justify-between px-8 md:px-12 lg:px-16 xl:px-20 py-4 items-center border-white border-t-2">
          <p className="text-white">© 2023 Trellis Protocol</p>
          <ul className="flex items-center space-x-4">
            <li>
              <a href="https://t.me/trellis_fi">
                <img src={TelegramIcon} className="cursor-pointer p-2 bg-[#504E4E] rounded-sm" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/Trellis_fi">
                <img src={TwitterIcon} className="cursor-pointer p-2 bg-[#504E4E] rounded-sm" />
              </a>
            </li>
            <li>
              <a href="https://github.com/Trellis-fi">
                <img src={GithubIcon} className="cursor-pointer p-2 bg-[#504E4E] rounded-sm" />
              </a>
            </li>
            <li>
              <a href="https://trellisfi.medium.com/">
                <img src={MediumIcon} className="cursor-pointer p-2 bg-[#504E4E] rounded-sm" />
              </a>
            </li>
            <li>
              <a href="https://discord.gg/uC4FkcUERC">
                <img src={DiscordIcon} className="cursor-pointer p-2 bg-[#504E4E] rounded-sm" />
              </a>
            </li>
          </ul>
        </div>
      </section>
    
    </div>
    )
}


export default Landing;